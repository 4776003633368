import React from 'react';
import { Link } from 'gatsby';
import 'tachyons';


const Footer = () => {
  return (
    <footer className="mt-auto pa2 bg-dark-gray near-white pv5">
      <div className="flex flex-wrap justify-around w-100 mw9 center mb5">
      </div>
      <div className="w-100 mw9 center silver mb3">
        <div className="w-100 bb b--mid-gray mv3"></div>
        <div className="flex w-100 mw6 items-center justify-center justify-start-ns">
          <a href="/sitemap.xml" className="silver sans-serif f5 tracked pv1 db mh1">SITEMAP</a>
          <span className="mh1">|</span>
          <Link to="/privacy" className="silver sans-serif f5 tracked pv1 db mh1">PRIVACY</Link>
          <span className="mh1">|</span>
          <a href="https://github.com/madelyneriksen/gatsby-starter-tyra"
             className="silver sans-serif f5 tracked pv1 db mh1">THEME</a>
        </div>
      </div>
      <div className="w-100 mw9 silver center sans-serif f6">
        <p>Akusmatika (C) 2022 El Discreto Encanto.</p>
      </div>
    </footer>
  )
}

export default Footer;

