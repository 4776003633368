import React from 'react';
import Helmet from 'react-helmet';
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import 'tachyons';
import '../styles/custom.tachyons.css';
import '../../styles/global.css'


const Layout = (props) => (
  <React.Fragment className={"bg-near-white"}>
    {/* This is to be able to locate the footer at the end of the screen */}
    <div className="flex flex-col min-h-screen">
    <Helmet>
      <body className="bg-near-white mid-gray" />
    </Helmet>
    <Navbar />
      {props.children}
    <Footer />
    </div>
  </React.Fragment>
)

export default Layout;
